import * as React from 'react';
import { useLocation } from '@reach/router';
import Header from '../header/header.component';
import Hero from '../hero/hero.component';
import Footer from '../footer/footer.component';

const Layout = ({ children, SEOConfig }) => {
  const location = useLocation();
  return (
    <>
      <Header SEOConfig={SEOConfig} />
      {location.pathname === '/' && <Hero />}
      {children}
      <Footer />
    </>
  );
};

export default Layout;
