import React from 'react';

const Pattern = ({
  className,
  SVGHeight,
  SVGWidth,
  patternID,
  patternHeight,
  patternWidth,
  rectHeight,
  rectWidth,
}) => {
  return (
    <svg
      className={`absolute ${className}`}
      width={SVGWidth}
      height={SVGHeight}
      fill='none'
      viewBox='0 0 404 784'
    >
      <defs>
        <pattern
          id={patternID}
          x={0}
          y={0}
          width={patternWidth}
          height={patternHeight}
          patternUnits='userSpaceOnUse'
        >
          <rect
            x={0}
            y={0}
            width={4}
            height={4}
            className='text-blue-gray-200'
            fill='currentColor'
          />
        </pattern>
      </defs>
      <rect width={rectWidth} height={rectHeight} fill={`url(#${patternID})`} />
    </svg>
  );
};

export default Pattern;
