import React from 'react';
import { Disclosure } from '@headlessui/react';
import { Link } from 'gatsby';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import Search from '../search/search.component';
import CustomLink from '../custom-link/custom-link.component';

import Logo from '../../images/const_logo.svg';

const Header = ({ SEOConfig }) => {
  const twitterMessage = SEOConfig?.title
    ? `Currently reading "${SEOConfig.title}"`
    : 'Start learning JavaScript with the const community!';
  const twitterUrl = SEOConfig?.postUrl || '';
  const completeURL=`https://twitter.com/intent/tweet?text=${twitterMessage}&hashtags=constjs,webdev,js,javascript&url=${process.env.GATSBY_BASE_URL}${twitterUrl}`
  return (
    <Disclosure as='nav' className='bg-white shadow'>
      {({ open }) => (
        <>
          <div className='max-w-7xl mx-auto px-2 sm:px-4 lg:px-8'>
            <div className='flex justify-between h-16'>
              <div className='flex px-2 lg:px-0'>
                <div className='flex-shrink-0 flex items-center'>
                  <Link to='/'>
                    <img
                      className='block lg:hidden h-8 w-auto'
                      src={Logo}
                      alt='const'
                    />
                    <img
                      className='hidden lg:block h-8 w-auto'
                      src={Logo}
                      alt='const'
                    />
                  </Link>
                </div>
                <div className='hidden lg:ml-6 lg:flex lg:space-x-8'>
                  <CustomLink url='/lectures' type='nav'>
                    Lectures
                  </CustomLink>
                  <CustomLink url='/blogs' type='nav'>
                    Blog
                  </CustomLink>
                  <CustomLink url='/contact' type='nav'>
                    Contact
                  </CustomLink>
                </div>
              </div>
              <Search />
              <div className='flex items-center lg:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
              <div className='hidden lg:ml-4 lg:flex lg:items-center'>
                <a
                  href={completeURL}
                  type='link-button'
                  data-social-network='Twitter'
                  data-social-action='tweet'
                  data-social-target='https://constjs.dev/'
                  rel='noreferrer'
                  target='_blank'
                  className='px-4 py-2 text-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none inline-flex items-center justify-center border border-transparent font-medium rounded-md shadow-sm font-fira'
                >
                  Tweet
                  <svg
                    fill='currentColor'
                    viewBox='0 0 24 24'
                    className='ml-2 -mr-1 h-5 w-5'
                    aria-hidden='true'
                  >
                    <path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='lg:hidden'>
            <div className='pt-2 pb-3 space-y-1'>
              <CustomLink url='/lectures' type='mobile'>
                Lectures
              </CustomLink>
              <CustomLink url='/blogs' type='mobile'>
                Blog
              </CustomLink>
            </div>
            <div className='pt-4 pb-3 border-t border-gray-200'>
              <div className='flex items-center px-4'>
                <div className='text-base font-medium font-fira text-blue-gray-600'>
                  Tweet
                </div>
                <a
                  className='ml-auto flex-shrink-0 bg-white p-1 text-purple-500 rounded-full hover:text-purple-600 focus:outline-none'
                  data-social-network='Twitter'
                  data-social-action='tweet'
                  data-social-target='https://constjs.dev/'
                  rel='noreferrer'
                  target='_blank'
                  href={completeURL}
                >
                  <span className='sr-only'>Tweet</span>
                  <svg
                    fill='currentColor'
                    viewBox='0 0 24 24'
                    className='h-6 w-6'
                    aria-hidden='true'
                  >
                    <path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
                  </svg>
                </a>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
