import React from 'react';

import CustomLink from '../custom-link/custom-link.component';

const HeroHeadline = () => {
  return (
    <div className='text-center'>
      <h1 className='text-4xl tracking-tight font-bold font-fira text-blue-gray-800 sm:text-5xl md:text-6xl'>
        <span className='block 3xl:inline'>Learning & teaching JavaScript</span>{' '}
        <span className='block text-purple-600 xl:inline'>the modern way!</span>
      </h1>
      <p className='mt-3 max-w-md mx-auto font-osans text-base text-blue-gray-500 sm:text-md md:mt-5 md:text-lg md:max-w-3xl'>
        Const is a community of passionate JavaScript and Web Development students who
        are dedicated to learn and teach JavaScript with a brand new mindset!
      </p>
      <div className='mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8'>
        <div className='rounded-md shadow'>
          <CustomLink
            url='/lectures'
            type='link-button'
            custom='w-full px-8 py-3 text-base text-white bg-purple-600 hover:bg-purple-700 md:py-4 md:text-lg md:px-10'
          >
            Lectures
          </CustomLink>
        </div>
        <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
          <CustomLink
            url='/blogs'
            type='link-button'
            custom='w-full px-8 py-3 text-base text-purple-600 bg-white hover:bg-blue-gray-50 md:py-4 md:text-lg md:px-10'
          >
            Articles
          </CustomLink>
        </div>
      </div>
    </div>
  );
};

export default HeroHeadline;
