import React, { useState, useEffect, useCallback } from 'react';
import * as JSSearch from 'js-search';
import { SearchIcon } from '@heroicons/react/solid';
import { navigate } from 'gatsby';

import searchIndex from '../../data/search-index.json';

import SearchResult from './search-result.component';

const Search = () => {
  const [searchState, setSearchState] = useState({
    searchResults: [],
    searchEngine: {},
    searchQuery: '',
  });

  const buildIndex = useCallback(() => {
    const search = new JSSearch.Search('slug');
    search.searchIndex = new JSSearch.TfIdfSearchIndex('slug');

    search.addIndex('title');
    search.addIndex('description');
    search.addDocuments(searchIndex.posts);

    setSearchState((prvSearchState) => {
      return { ...prvSearchState, searchEngine: search };
    });
  }, []);

  useEffect(() => {
    buildIndex();
  }, [buildIndex]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    const searchQueryResults = searchState.searchEngine.search(value);
    setSearchState({
      ...searchState,
      searchResults: searchQueryResults,
      searchQuery: e.target.value,
    });
  };

  return (
    <div className='flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end'>
      <div className='max-w-lg w-full lg:max-w-xs'>
        <label htmlFor='search' className='sr-only'>
          Search
        </label>
        <div className='relative'>
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
            <SearchIcon
              className='h-5 w-5 text-blue-gray-400'
              aria-hidden='true'
            />
          </div>
          <input
            id='search'
            name='search'
            onChange={handleInputChange}
            value={searchState.searchQuery}
            className='block w-full pl-10 pr-3 py-2 border border-blue-gray-300 rounded-md leading-5 bg-white placeholder-blue-gray-500 focus:outline-none focus:placeholder-blue-gray-400 focus:ring-1 focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-fira'
            placeholder='Search'
            type='search'
          />
          {searchState.searchResults.length > 0 && (
            <div className='absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0'>
              <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-y-auto max-h-80 scrollbar-w-2 scrollbar-track-purple-lighter scrollbar-thumb-rounded scrollbar-thumb-purple'>
                <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8'>
                  {searchState.searchResults.map((post) => (
                    <SearchResult
                      key={post.slug}
                      post={post}
                      handleClick={() =>
                        navigate(
                          post.post === 'lecture'
                            ? `/lectures/${post.slug}`
                            : `/blogs/${post.slug}`
                        )
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
