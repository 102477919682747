import React from 'react';
import { DocumentTextIcon, NewspaperIcon } from '@heroicons/react/outline';

const SearchResult = ({ handleClick, post }) => {
  return (
    <span
      onClick={handleClick}
      className='-m-3 p-3 flex items-start rounded-lg hover:bg-blue-gray-50 cursor-pointer'
    >
      {post.post === 'lecture' ? (
        <DocumentTextIcon
          className='flex-shrink-0 h-6 w-6 text-purple-600'
          aria-hidden='true'
        />
      ) : (
        <NewspaperIcon
          className='flex-shrink-0 h-6 w-6 text-purple-600'
          aria-hidden='true'
        />
      )}
      <div className='ml-4'>
        <p className='text-base font-medium text-blue-gray-800'>{post.title}</p>
        <p className='mt-1 text-sm text-blue-gray-500'>{post.description}</p>
      </div>
    </span>
  );
};

export default SearchResult;
