import React from 'react';

import Pattern from '../pattern/pattern.component';
import HeroHeadline from '../hero/hero-headline.component';

const Hero = () => {
  return (
    <div className='relative bg-blue-gray-50 overflow-hidden'>
      <div
        className='hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full'
        aria-hidden='true'
      >
        <div className='relative h-full max-w-7xl mx-auto'>
          <Pattern
            className={
              'right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2'
            }
            SVGHeight={784}
            SVGWidth={404}
            patternID={'f210dbf6-a58d-4871-961e-36d5016a0f49'}
            patternHeight={20}
            patternWidth={20}
            rectHeight={404}
            rectWidth={784}
          />
          <Pattern
            className={
              'left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2'
            }
            SVGHeight={784}
            SVGWidth={404}
            patternID={'5d0dd344-b041-4d26-bec4-8d33ea57ec9b'}
            patternHeight={20}
            patternWidth={20}
            rectHeight={404}
            rectWidth={784}
          />
        </div>
      </div>
      <div className='relative pt-6 pb-16 sm:pb-24'>
        <main className='mt-16 mx-auto max-w-7xl px-4 sm:mt-24'>
          <HeroHeadline />
        </main>
      </div>
    </div>
  );
};

export default Hero;
